export function formatPrice(price) {
    return (window.Propuno && window.Propuno.currency ? window.Propuno.currency : "€") + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatToTitleCase(data) {
    return data
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

export function formatForURL(val) {
    return encodeURIComponent(val.toString().toLowerCase().split(" ").join("-")) + "/";
}

export function formatLocationForURL(val) {
    return encodeURIComponent(val.toString().split(",").join("").split(" / ").join("-").split(" - ").join("-")).toLowerCase().split("%20").join("-") + "/";
}

export function formatForCurrency(val) {
    return (window.Propuno && window.Propuno.currency ? window.Propuno.currency : "€") + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}