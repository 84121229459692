<template>
    <div class="property-alert-form">
        <div v-if="success">
            <div class="alert alert-success" role="alert">
                You will receive email alerts when properties matching your criteria are listed
            </div>
        </div>

        <div v-if="!success">
            <div class="row mb-4">
                <div class="col-md-12">
                    <input type="text" class="form-control border mb-2" v-model="form.fullname" placeholder="Full name" required>
                    <span class="invalid-feedback mb-2" role="alert" v-if="errors.fullname">
                        <strong>{{ errors.fullname[0] }}</strong>
                    </span>
                </div>
                <div class="col-md-12">
                    <input type="email" class="form-control border mb-2" v-model="form.email" placeholder="Email address" required>
                    <span class="invalid-feedback mb-2" role="alert" v-if="errors.email">
                        <strong>{{ errors.email[0] }}</strong>
                    </span>
                </div>
                <div class="col-md-12">
                    <input type="tel" class="form-control border mb-2" v-model="form.telephone" placeholder="Telephone" required>
                    <span class="invalid-feedback mb-2" role="alert" v-if="errors.telephone">
                        <strong>{{ errors.telephone[0] }}</strong>
                    </span>
                </div>
                <div class="col-md-12">
                    <v-select class="vselect-border mb-2" v-model="form.location" :options="data.locations" placeholder="Type your search location" />
                </div>
                <div class="col-md-12">
                    <select class="form-control custom-select normal border mb-2" v-model="form.propertyType">
                        <option value="0">Property Type</option>
                        <option v-for="type in data.propertyTypes" :key="type.id" :value="type.id">{{ type.name }}</option>
                    </select>
                </div>
                <div class="col-md-12">
                    <select class="form-control custom-select normal border mb-2" v-model="form.beds">
                        <option value="0">Beds</option>
                        <option v-for="bed in data.beds" :key="bed" :value="bed">{{ bed }}</option>
                    </select>
                </div>
                <div class="col-md-12">
                    <select class="form-control custom-select normal border mb-2" v-model="form.minPrice">
                        <option value="0">Min Price</option>
                        <option v-for="n in data.minPrices.length" :key="n" :value="data.basePrices[(n - 1)]">{{ renderCurrency(data.minPrices[(n - 1)]) }}</option>
                    </select>
                </div>
                <div class="col-md-12">
                    <select class="form-control custom-select border normal mb-2" v-model="form.maxPrice">
                        <option value="0">Max Price</option>
                        <option v-for="n in data.maxPrices.length" :key="n" :value="data.basePrices[(n - 1)]">{{ renderCurrency(data.maxPrices[(n - 1)]) }}</option>
                    </select>
                </div>
                <div class="col-md-12">
                    <button type="submit" class="btn btn-green" @click="createAlert">Create Alert</button>
                    <button class="btn btn-blue" @click.prevent="resetForm">Reset</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {formatForCurrency} from '../Helpers';

export default {
    name: 'property-alert-form',
    data() {
        return {
            success: false,
            errors: {
                email: [],
                fullname: [],
                telephone: []
            },
            data: {
                locations: [],
                propertyTypes: [],
                beds: [],
                minPrices: [],
                maxPrices: []
            },
            form: {
                fullname: '',
                email: '',
                telephone: '',
                location: '',
                propertyType: 0,
                beds: 0,
                minPrice: 0,
                maxPrice: 0
            }
        };
    },
    created() {
        this.data.propertyTypes = window.Propuno.property_types;
        //this.data.locations = window.Propuno.locations;
        this.data.minPrices = window.Propuno.sale_prices;
        this.data.maxPrices = window.Propuno.sale_prices;
        this.data.beds = window.Propuno.beds;
        this.data.basePrices = window.Propuno.sale_base_prices;

        var locations = window.Propuno.countries;
        locations = locations.concat(window.Propuno.cities);
        locations = locations.concat(window.Propuno.towns);
        this.data.locations = locations.filter(function(item, pos) {
            return locations.indexOf(item) == pos;
        });
    },
    methods: {
        renderCurrency(val) {
            return formatForCurrency(val);
        },
        resetForm() {
            this.form = {
                fullname: '',
                email: '',
                contact: '',
                location: '',
                propertyType: 0,
                beds: 0,
                minPrice: 0,
                maxPrice: 0
            };
            this.errors = {
                email: [],
                fullname: [],
                telephone: []
            };
        },
        createAlert: function() {
            axios.post('/api/property-alert', this.form)
                .then((response) => {
                    if (response.data.success) {
                        this.success = true;
                    } else {
                        this.errors = response.data;
                    }
                })
                .catch((error) => {

                })
        }
    }
}
</script>

<style>
    .invalid-feedback {
        display: block;
        position: relative;
        top: -0.25rem;
    }
</style>
