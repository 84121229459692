export default {
    state: {
        showQuickSearch: false,
        quickSearchInput: '',
    },
    getters: {
        quickSearchInput(state) {
            return state.quickSearchInput;
        },
    },
    mutations: {
        updateShowQuickSearch: function(state, value) {
            state.showQuickSearch = value;
        },
        updateQuickSearchInput: function(state, value) {
            state.quickSearchInput = value;
        },
    },
}
