<template>
    <div class="searchable-dropdown" @keydown.down="onArrowDown" @keydown.up="onArrowUp" @keyup.enter="onEnter">
        <input type="text" name="propuno-location" autocomplete="propuno-location" class="form-control custom-select location" :placeholder="placeholder" @input="userSearch" :value="search" @keydown="show = true" @click="show = !show" />
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" class="reset" @click="reset" v-if="search && search != ''"><path d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"></path></svg>
        <div class="results bg-white shadow pt-3 pb-2" :class="dropdownClass" ref="scrollContainer">
            <template v-if="renderCountries && renderCountries.length > 0">
                <p class="text-muted text-uppercase font-weight-bold small mb-0 px-3">Countries</p>
                <p class="item mb-0 px-3 py-1" v-for="country in renderCountries" :key="country + 'country'" :ref="'r' + getIndexOf(country)" @click="chooseItem(country)" :class="selectClass(country)">{{ country }}</p>
            </template>
            <template v-if="renderCities && renderCities.length > 0">
                <p class="text-muted text-uppercase font-weight-bold small mb-0 px-3" :class="{ 'mt-3': (renderCountries && renderCountries.length > 0) }">Cities</p>
                <p class="item mb-0 px-3 py-1" v-for="city in renderCities" :key="city + 'city'" :ref="'r' + getIndexOf(city)" @click="chooseItem(city)" :class="selectClass(city)">{{ city }}</p>
            </template>
            <template v-if="renderTowns && renderTowns.length > 0">
                <p class="text-muted text-uppercase font-weight-bold small mb-0 px-3" :class="spacingClass">Towns</p>
                <p class="item mb-0 px-3 py-1" v-for="town in renderTowns" :key="town + 'town'" :ref="'r' + getIndexOf(town)" @click="chooseItem(town)" :class="selectClass(town)">{{ town }}</p>
            </template>
            <template v-if="(!renderCountries || renderCountries.length <= 0) && (!renderCities || renderCities.length <= 0) && (!renderTowns || renderTowns.length <= 0)">
                <p class="item mb-0 px-3 py-1">No results found</p>
            </template>
        </div>
    </div>
</template>

<script>
//npm install fuzzyset
//import FuzzySet from 'FuzzySet';

export default {
    name: 'searchable-dropdown',
    data() {
        return {
            search: '',
            scrollPos: '',
            show: false
            //fuzzyCountries: null
        };
    },
    props: ['countries', 'cities', 'towns', 'default', 'placeholder'],
    created() {
        if(this.default) {
            this.search = this.default;
        }
        this.scrollPos = -1;
        //this.fuzzyCountries = FuzzySet(this.cities);
    },
    mounted() {
        document.addEventListener('click', this.close);
    },
    beforeDestroy () {
        document.removeEventListener('click', this.close);
    },
    methods: {
        userSearch(event) {
            this.search = event.target.value;
            this.scrollPos = -1;
            this.$refs.scrollContainer.scrollTop = 0;
        },
        chooseItem(item) {
            this.search = item;
            this.$emit('item-selected', item);
            this.show = false;
            this.scrollPos = -1;
        },
        reset() {
            this.chooseItem('');
        },
        close(e) {
            if (!this.$el.contains(e.target)) {
                this.show = false
            }
        },
        selectClass(d) {
            return this.getIndexOf(d) == this.scrollPos ? "scroll" : "";
        },
        getIndexOf(d) {
            return this.allResults.indexOf(d);
        },
        onArrowDown(e) {
            e.preventDefault()
            this.scrollPos++;
            this.arrowChecks();
        },
        onArrowUp(e) {
            e.preventDefault()
            this.scrollPos--;
            this.arrowChecks();
        },
        arrowChecks() {
            if (this.scrollPos > this.allResults.length - 1) {
                this.scrollPos = this.allResults.length - 1;
            } else if (this.scrollPos < 0) {
                this.scrollPos = 0;
            }
            this.fixScrolling();
        },
        onEnter() {
            if (this.scrollPos != -1) {
                this.chooseItem(this.allResults[this.scrollPos]);
            }
        },
        fixScrolling() {
            this.$refs.scrollContainer.scrollTop = this.$refs['r' + this.scrollPos][0].offsetTop;
        },
    },
    computed: {
        spacingClass() {
            var array = [this.renderCountries.length, this.renderCities.length, this.renderTowns.length];
            var count = 0;
            array.forEach((v) => {
                if (v > 0) {
                    count++;
                }
            });

            return count > 1 ? "mt-3" : "";
        },
        dropdownClass() {
            return this.show ? "show" : "hide";
        },
        searchTerm() {
            if (!this.search) {
                return false;
            }

            return this.search.toString().toLowerCase();
        },
        /*
        renderCountries2() {
            if (this.searchTerm) {
                var results = [];
                var d = this.fuzzyCountries.get(this.searchTerm, this.cities, .33);
                d.forEach((r) => {
                    if (Array.isArray(r)) {
                        results.push(r[1]);
                    } else {
                        results.push(r);
                    }
                });
                return results;
            }

            return this.cities;
        },
        */
        renderCountries() {
            var data = this.countries;
            if (this.searchTerm) {
                data = this.countries.filter((d) => {
                    return d.toString().toLowerCase().includes(this.searchTerm);
                });
            }

            return data;
        },
        renderCities() {
            var data = this.cities;
            if (this.searchTerm) {
                data = this.cities.filter((d) => {
                    return d.toString().toLowerCase().includes(this.searchTerm);
                });
            }

            return data;
        },
        renderTowns() {
            var data = this.towns;
            if (this.searchTerm) {
                data = this.towns.filter((d) => {
                    return d.toString().toLowerCase().includes(this.searchTerm);
                });
            }

            return data;
        },
        allResults() {
            return this.renderCountries.concat(this.renderCities).concat(this.renderTowns);
        },
        totalCount() {
            return this.renderCountries.length + this.renderCities.length + this.renderTowns.length;
        }
    }
}
</script>

<style scoped>
    p {
        font-size: 1rem;
        color: #212529;
    }
    .small {
        font-size: 80%;
    }
    .searchable-dropdown {
        position: relative;
    }
    .results {
        position: absolute;
        width: 100%;
        max-height: 400px;
        background-color: #ffffff;
        overflow-y: scroll;
        overflow-x: hidden;
        z-index: 910;
    }
    .item {
        cursor: pointer;
    }
    .item:hover {
        background-color: #0e97d5;
        color: #ffffff;
    }
    .scroll {
        background-color: #0e97d5;
        color: #ffffff;
    }
    .reset {
        position: absolute;
        top: calc(50% - 5px);
        right: 32px;
        fill: rgba(60, 60, 60, 0.5);
        cursor: pointer;
    }
    .hide {
        display: none;
    }
    .show {
        display: block;
    }
</style>
