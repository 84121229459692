$('.loginRegContinueForm').submit(function(e){
    e.preventDefault();
    var values = {};
    $.each($(this).serializeArray(), function(i, field) {
        values[field.name] = field.value;
    });

    if (values.email && values.email != "" && /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(values.email)) {
        $('.loginRegContinueFormButton').each(function(){
            $(this).text('Loading...');
            $(this).attr("disabled", true);
        });
        $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            type: "GET",
            dataType: "json",
            url: "/api/is-registered/" + values.email,
            processData: false,
            contentType: false,
            cache: false,
            success: function(e){
                const modalToOpen = e.is_registered ? '#welcomeBackModal' : '#newUserModal';
                $('#loginModal').modal('hide');
                $('#exitIntentModal').modal('hide');
                $(modalToOpen).modal('show');
                $(modalToOpen + 'Email').val(values.email);

                $('.loginRegContinueFormButton').each(function(){
                    $(this).text('Continue');
                    $(this).attr("disabled", false);
                });
            },
            fail: function(e) {
                $('.loginRegContinueFormButton').each(function(){
                    $(this).text('Continue');
                    $(this).attr("disabled", false);
                });
            }
        });
    }
});

$('#welcomeBack').submit(function(e) {
    if ($('#welcomeBackModalCheck').val() == 1) {
        e.preventDefault();
        var form = $(this);
        var formdata = false;
        if (window.FormData){
            formdata = new FormData(form[0]);
        }

        $('.welcomeBackFormButton').text('Loading...');
        $('.welcomeBackFormButton').attr("disabled", true);

        $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            type: "POST",
            dataType: "json",
            url: "/api/login",
            data: formdata,
            processData: false,
            contentType: false,
            cache: false,
            success: function(e) {
                if (e.errors || !e.success) {
                    $('#welcomeBackErrors').html("Your password does not match our records.");
                    $('#welcomeBackErrors').removeClass('d-none');

                    $('.welcomeBackFormButton').text('Log In');
                    $('.welcomeBackFormButton').attr("disabled", false);
                } else {
                    $('#welcomeBackModalCheck').val(0);
                    $('#welcomeBack').submit();
                }
            },
            fail: function(e) {
                $('#welcomeBackErrors').html("Something went wrong. Please try again later");
                $('#welcomeBackErrors').removeClass('d-none');

                $('.welcomeBackFormButton').text('Log In');
                $('.welcomeBackFormButton').attr("disabled", false);
            }
        });
    }
});

$('#newUser').submit(function(e) {
    if ($('#newUserModalCheck').val() == 1) {
        e.preventDefault();
        var form = $(this);
        var formdata = false;
        if (window.FormData){
            formdata = new FormData(form[0]);
        }

        $('.newUserModalCheckFormButton').text('Loading...');
        $('.newUserModalCheckFormButton').attr("disabled", true);

        $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            type: "POST",
            dataType: "json",
            url: "/api/register",
            data: formdata,
            processData: false,
            contentType: false,
            cache: false,
            success: function(e) {
                if (e.errors || !e.success) {
                    var errorMessage = "Your account could not be created. Please try again later.";
                    if (e.errors) {
                        errorMessage = e.errors;
                    }

                    $('#newUserErrors').html(errorMessage);
                    $('#newUserErrors').removeClass('d-none');

                    $('.newUserModalCheckFormButton').text('Register Now');
                    $('.newUserModalCheckFormButton').attr("disabled", false);
                } else {
                    $('#newUserModalCheck').val(0);
                    $('#newUser').submit();
                }
            },
            fail: function(e) {
                $('#newUserErrors').html("Your account could not be created. Please try again later.");
                $('#newUserErrors').removeClass('d-none');

                $('.newUserModalCheckFormButton').text('Register Now');
                $('.newUserModalCheckFormButton').attr("disabled", false);
            }
        });
    }
});
