<template>
    <div class="quick-search-form-input mb-0 pb-0 ml-md-3">
        <input @click="updateShow" :value="searchInput" @input="updateSearchInput" class="quick-search-input form-control" type="search" placeholder="What are you looking for?" aria-label="Search">
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'quicksearch-opener',
    methods: {
        updateShow: function() {
            this.$store.commit('updateShowQuickSearch', true);
        },
        updateSearchInput: function(e) {
            this.updateShow();
            this.$store.commit('updateQuickSearchInput', e.target.value)
        },
    },
    computed: {
        ...mapState({
            searchInput: state => state.quickSearchInput
        })
    }
}
</script>
