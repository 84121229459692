import Vue from 'vue';
import Vuex from 'vuex';
import StoreData from './store';
import vSelect from 'vue-select';
import SearchLocationForm from './vue/components/SearchLocationForm.vue';
import PropertyAlertForm from './vue/components/PropertyAlertForm.vue';
import QuickSearchOpener from './vue/components/QuickSearchOpener.vue';
import QuickSearch from './vue/components/QuickSearch.vue';

window.Vue = Vue;

Vue.use(Vuex);
const store = new Vuex.Store(StoreData);

require('./bootstrap');

vSelect.props.components.default = () => ({
    /*
    Deselect: {
        render: createElement => createElement('span', ''),
    },
    */
    OpenIndicator: {
        render: createElement => createElement('span', ''),
    },
});

Vue.component('v-select', vSelect);
Vue.component('property-alert-form', PropertyAlertForm);
Vue.component('search-location-form', SearchLocationForm);
Vue.component('quicksearch-opener', QuickSearchOpener);
Vue.component('quicksearch', QuickSearch);

var app = new Vue({
    el: '#app',
    store: store,
});

var $ = require('jquery');
window.$ = $;
window.jQuery = $;
require('bootstrap');
require('slick-carousel');
require('./login-ajax');

import lozad from 'lozad';
window.Lozad = lozad(); // lazy loads elements with default selector as '.lozad'
window.Lozad.observe();

$(".hamburger").click(function(){$(".hamburger").toggleClass("is-active")});$(".search-toggle").click(function(){$(".search-wrap").toggleClass("is-active")});

// Example starter JavaScript for disabling form submissions if there are invalid fields
(function() {
    'use strict';
    window.addEventListener('load', function() {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.getElementsByClassName('needs-validation');
        // Loop over them and prevent submission
        var validation = Array.prototype.filter.call(forms, function(form) {
            form.addEventListener('submit', function(event) {
                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                }
                form.classList.add('was-validated');
            }, false);
        });
    }, false);
})();

$('.categories').slick(
{
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    centerMode: false,
    variableWidth: false,
    prevArrow: '<div class="slick-left-arrow slick-arrow d-flex align-items-center justify-content-center"><img src="assets/img/left-arrow-min.png" alt="Left Arrow Icon"></div>',
    nextArrow: '<div class="slick-right-arrow slick-arrow d-flex align-items-center justify-content-center"><img src="assets/img/right-arrow-min.png" alt="Right Arrow Icon"></div>',
    responsive: [
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 4
            }
        },
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 760,
            settings: {
                slidesToShow: 1
            }
        }
    ]
});

$('.special-slider').slick(
{
    dots: false,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    centerMode: false,
    variableWidth: false,
    responsive: [
        {
            breakpoint: 760,
            settings: {
                adaptiveHeight: true
            }
        }
    ]
});

$('.special-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide)
{
    $('.special-image').fadeOut(50, function() {
        $('.special-image').attr('src', 'assets/img/highlight' + (nextSlide + 1) + '-min.jpg');
    }).fadeIn(1000);
});

$('.special-slider').on('swipe', function(event, slick, currentSlide, nextSlide)
{
    $('.special-control').toggleClass('flip');
});

$('.special-control').click(function()
{
    $('.special-control').toggleClass('flip');
    $('.special-slider').slick('slickNext');
});
