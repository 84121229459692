<template>
    <div id="search" class="search-full-page bg-white" ref="search" v-if="show">
        <div class="bg-gray mb-5">
            <div class="close-icon" @click="show = false"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-x" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#b9bec4" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="12" r="9" /><path d="M10 10l4 4m0 -4l-4 4" /></svg></div>
            <div class="container">
                <div class="row pt-4 bg-gray">
                    <div class="col">
                        <div class="quick-search-form-input mb-2">
                            <input type="text" class="form-control search-input" placeholder="What are you looking for?" :value="searchInput" @input="updateSearchInput">
                        </div>
                    </div>
                    <div class="col-auto d-flex align-items-end pl-0" v-if="false == true">
                        <button @click="show = false" class="btn btn-link p-0 mb-2"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-microphone" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><rect x="9" y="2" width="6" height="11" rx="3" /><path d="M5 10a7 7 0 0 0 14 0" /><line x1="8" y1="21" x2="16" y2="21" /><line x1="12" y1="17" x2="12" y2="21" /></svg></button>
                    </div>
                </div>
                <div class="row pb-4 bg-gray">
                    <div class="col-md-12">
                        <p class="text-danger text-small font-bold" v-if="!fetchingProperties && !firstLoad && searchInput && showHint">Try using more keywords in your search such as locations and property types</p>
                        <p class="badge badge-light pointer" @click="updateSearch('Apartments in Malaga')">Apartments in Malaga</p>
                        <p class="badge badge-light pointer" @click="updateSearch('Villas in Spain')">Villas in Spain</p>
                        <p class="badge badge-light pointer" @click="updateSearch('4 Bed Apartments in Estepona')">4 Bed Apartments in Estepona</p>
                    </div>
                    <div class="col-md-12">
                        <a :href="searchURL" target="_blank" class="refine d-flex align-items-center"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-adjustments" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="6" cy="10" r="2" /><line x1="6" y1="4" x2="6" y2="8" /><line x1="6" y1="12" x2="6" y2="20" /><circle cx="12" cy="16" r="2" /><line x1="12" y1="4" x2="12" y2="14" /><line x1="12" y1="18" x2="12" y2="20" /><circle cx="18" cy="7" r="2" /><line x1="18" y1="4" x2="18" y2="5" /><line x1="18" y1="9" x2="18" y2="20" /></svg> Refine your Search</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" v-if="fetchingProperties || firstLoad">
            <div class="row search-title-row mt-md-4">
                <div class="col-md-6">
                    <div class="placeholder d-flex align-items-center">
                        <div class="p-text p-header p-line"></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6" v-for="n in 15" :key="n">
                    <div class="placeholder d-flex align-items-center mt-3">
                        <div class="p-box p-property"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pb-5" v-if="!fetchingProperties && !firstLoad">
            <div class="container">
                <slot name="no-properties" v-if="!properties || !properties.data || properties.data.length <= 0">
                    <h1 class="title mb-0">No
                        {{ propertyBedsDisplay ? propertyBedsDisplay : '' }}
                        {{ propertyTypeDisplay ? propertyTypeDisplay : 'Properties' }}
                        Found For Sale
                        {{ location ? 'in ' + location : 'Worldwide' }}
                    </h1>
                </slot>
                <div class="row search-title-row" v-if="properties && properties.data && properties.data.length > 0">
                    <div class="col-12">
                        <h1 class="title mb-0">
                            {{ propertyBedsDisplay ? propertyBedsDisplay : '' }}
                            {{ propertyTypeDisplay ? propertyTypeDisplay : 'Property' }} For Sale {{ location ? 'in ' + location : 'Worldwide' }}
                            <span>({{ formatNumber(properties.meta.total) }})</span>
                        </h1>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row" ref="results" v-if="properties && properties.data && properties.data.length > 0">
                    <div class="col-lg-4 col-md-6" v-for="property in properties.data" :key="property.ref">
                        <div class="property mt-3">
                            <img class="property-image" :src="property.primary_photo_url" :alt="'Property ' + property.ref">
                            <a :href="property.url" target="_blank" class="d-block bottom ontop d-flex flex-row block-mobile align-items-center">
                                <p class="flex-fill"><b class="down5">{{ property.display_name }}</b>{{ property.display_location }}</p>
                                <p class="price"><b>{{ property.display_price }}</b></p>
                            </a>
                            <div class="hover ontop">
                                <p class="pb-4">{{ property.display_bed_bath }}</p>
                                <a :href="property.url" target="_blank" class="btn btn-green">View Details</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" v-if="pagination.maxPage > 1">
                        <nav class="mt-4">
                            <ul class="pagination pagination-sm justify-content-end">
                                <li class="page-item" v-if="pagination.page > 1"><a @click="changePage(parseInt(pagination.page) - 1)" class="page-link" rel="prev"><svg width="12px" height="12px" viewBox="0 0 16 16" class="bi bi-chevron-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/></svg></a></li>
                                <li class="page-item" v-for="page in pageNumbers" :key="page" :class="{'active': page == pagination.page}"><a @click="changePage(page)" class="page-link">{{ page }}</a></li>
                                <li class="page-item" v-if="pagination.page < pagination.maxPage"><a @click="changePage(parseInt(pagination.page) + 1)" rel="next" aria-label="Next »" class="page-link"><svg width="12px" height="12px" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" class="bi bi-chevron-right"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path></svg></a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { debounce } from 'debounce';
import {formatLocationForURL} from '../Helpers';

export default {
    name: 'quick-search',
    data() {
        return {
            show: false,
            firstLoad: true,
            fetchingProperties: false,
            clickedQuickOption: false,
            showHint: false,
            pagination: {
                page: 1,
                oldPage: 1,
                perPage: 15,
                maxPage: 1
            },
            locations: [],
            location: '',
            properties: [],
            all_properties: 0,
            propertyBedsDisplay: '',
            propertyTypeDisplay: '',
            searchData: {
                location: '',
                type: '',
                bed: 0,
                bath: 0
            },
            searchIgnoreList: [' in ', ' and ', ' for '],
            remaps: {
                'homes': 'houses',
                'vilas': 'villas'
            }
        };
    },
    created() {
        var locations = window.Propuno.countries;
        locations = locations.concat(window.Propuno.cities);
        locations = locations.concat(window.Propuno.towns);
        this.locations = locations.filter(function(item, pos) {
            return locations.indexOf(item) == pos;
        });

        this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'updateShowQuickSearch') {
                this.show = state.showQuickSearch;

                if (this.firstLoad) {
                    this.fetchingProperties = true;
                    this.searchProperties("", 1);
                }
            }
        });

        document.addEventListener('keyup', (evt) => {
            if (evt.keyCode === 27) {
                this.show = false;
            }
        });
    },
    methods: {
        changePage: function(page) {
            document.activeElement.blur();
            this.searchProperties(this.searchInput, page);
        },
        updateSearchInput: function(e) {
            this.$store.commit('updateQuickSearchInput', e.target.value)
        },
        updateSearch: function(search) {
            this.$store.commit('updateQuickSearchInput', search)
        },
        isTypingWord(data, word) {
            return word.startsWith(data);
        },
        formatNumber(number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        searchProperties: debounce(function (search, page) {
            this.searchData.location = '';
            this.searchData.type = 'property';
            this.searchData.bed = 0;
            this.searchData.bath = 0;
            this.showHint = false;
            this.pagination.page = page;
            if (page != this.pagination.previousPage) {
                this.pagination.previousPage = page;
                this.fetchingProperties = true;
                this.$refs.search.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }

            search = search.toLowerCase();
            this.searchIgnoreList.forEach(element => {
                search = search.replace(element, " ");
            });
            this.location = '';
            this.propertyBedsDisplay = '';
            this.propertyTypeDisplay = '';
            var beds;
            var baths;
            var propertyTypeId;
            var types = window.Propuno.property_types;

            if (search && search.length > 2) {
                this.fetchingProperties = true;
                var check = search.toString().split(" ");
                for (var i = 0; i < check.length; i++) {
                    var toCheck = check[i].toLowerCase();
                    if (isNaN(toCheck) && toCheck.length > 2) {
                        for (const [key, value] of Object.entries(this.remaps)) {
                            if (this.isTypingWord(toCheck, key)) {
                                toCheck = value;
                                break;
                            }
                        }

                        var found = false;
                        for (let el of types) {
                            if (this.isTypingWord(toCheck, el.plural.toLowerCase()) || this.isTypingWord(toCheck, el.name.toLowerCase())) {
                                found = true;
                                propertyTypeId = el.id;
                                this.searchData.type = el.slug;
                                this.propertyTypeDisplay = el.plural;
                                break;
                            }
                        }

                        if (found) {
                            continue;
                        }

                        for (let el of this.locations) {
                            if (this.isTypingWord(toCheck, el.toLowerCase())) {
                                this.location = el;
                                this.searchData.location = formatLocationForURL(el);
                                break;
                            }
                        }
                    } else if (!isNaN(toCheck) && check[i + 1] && check[i + 1].length > 2) {
                        if (this.isTypingWord(check[i + 1].toLowerCase(), "beds") || this.isTypingWord(check[i + 1].toLowerCase(), "bedrooms")) {
                            beds = parseInt(toCheck);
                            this.searchData.bed = parseInt(toCheck);
                            this.propertyBedsDisplay = this.searchData.bed + " bedroom";
                        } else if (this.isTypingWord(check[i + 1].toLowerCase(), "baths") || this.isTypingWord(check[i + 1].toLowerCase(), "bathrooms")) {
                            baths = parseInt(toCheck);
                            this.searchData.bath = parseInt(toCheck);
                        }
                    }
                }
            }

            var parameters = "?page=" + this.pagination.page + (this.location ? "&location=" + this.location.toLowerCase().trim() : '') + (beds ? '&beds=' + beds : '')
                + (baths ? '&baths=' + baths : '') + (propertyTypeId ? '&property_type_id=' + propertyTypeId : '');
            //console.log('/api/properties' + parameters);
            //console.log('RAW: ' + this.searchInput)

            axios.get('/api/properties' + parameters)
                .then((response) => {
                    this.properties = response.data;
                    this.pagination.maxPage = response.data.meta.last_page;
                    this.fetchingProperties = false;

                    if (this.properties.meta.total == this.all_properties) {
                        this.showHint = true;
                    }
                }).catch((error) => {
                    this.properties = [];
                    this.fetchingProperties = false;
                    this.showHint = true;
                });
        }, 300),
    },
    computed: {
        searchURL: function() {
            return window.Propuno.base_url + this.searchData.type + "-for-sale/"
                + (this.searchData.location ? 'in/' + this.searchData.location : '')
                + (this.searchData.bed ? 'beds/' + this.searchData.bed + '/' : '');
        },
        pageNumbers: function() {
            if (this.pagination.maxPage <= 1) {
                return [];
            }

            let from = this.pagination.page - 5;
            if (from < 1) {
                from = 1;
            }

            let to = from + (2 * 5);
            if (to >= this.pagination.maxPage) {
                to = this.pagination.maxPage;
            }

            let pagesArray = [];
            for (let page = from; page <= to; page++) {
                pagesArray.push(page);
            }

            return pagesArray;
        },
        ...mapState({
            searchInput: state => state.quickSearchInput
        }),
    },
    watch : {
        show: function(val) {
            document.body.style.overflowY = val ? 'hidden' : 'scroll';
        },
        searchInput: function(value) {
            this.searchProperties(value, 1);
        },
        properties: function(value) {
            if (this.firstLoad) {
                this.firstLoad = false;
                this.all_properties = value.meta.total;
            }
        },
    }
}
</script>

<style scoped>
    .bg-gray {
        background-color: #f8fafc;
    }
    .search-full-page {
        position: fixed;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100vh;
        width: 100%;
        overflow-y: scroll;
        z-index: 9999999999;
    }
    .pointer:hover {
        cursor: pointer;
    }
    .close-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        z-index: 9999999999;
    }
    .close-icon svg {
        stroke: #b9bec4;
        transition: .15s ease-in-out;
        -webkit-transition: .15s ease-in-out;
        -moz-transition: .15s ease-in-out;
        -o-transition: .15s ease-in-out;
    }
    .close-icon:hover svg {
        stroke: #a4a9ae;
    }
    .refine, .refine:hover {
        color: #656363 !important;
        font-size: 0.95rem;
    }
    .refine svg {
        margin-right: 5px;
        stroke: #656363;
    }
    .badge {
        padding: 5px 10px;
        border-radius: 3px;
        font-size: .8rem;
        font-weight: 500;
        color: #212529;
        background-color: hsla(0,0%,100%,.6);
        transition: .15s ease-in-out;
        -webkit-transition: .15s ease-in-out;
        -moz-transition: .15s ease-in-out;
        -o-transition: .15s ease-in-out;
        cursor: pointer;
    }
    .badge:hover {
        background-color: hsla(0,0%,100%,.4);
    }
</style>
