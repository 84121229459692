<template>
    <div class="home-search mb-1">
        <div class="input-group">
            <searchable-dropdown v-on:item-selected="selectedLocation" :countries="countries" :cities="cities" :towns="towns" placeholder="Search for Town, City or Country"></searchable-dropdown>
            <div class="input-group-append">
                <button class="btn btn-green" @click="redirectToSearch">&nbsp;</button>
            </div>
        </div>
    </div>
</template>

<script>
import {formatLocationForURL} from '../Helpers';
import SearchableDropdown from '../components/SearchableDropdown.vue';

export default {
    name: 'search-location-form',
    data() {
        return {
            location: '',
            countries: [],
            cities: [],
            towns: [],
        };
    },
    components: {
        SearchableDropdown
    },
    created() {
        if (window.Propuno.locations) {
            var locations = window.Propuno.locations;
            for (var i = 0; i < locations.length; i++) {
                var location = String(locations[i]);
                if (location.includes(", ")) {
                    var check = location.split(", ");
                    if (check.length >= 3) {
                        this.towns.push(location);
                    } else {
                        this.cities.push(location);
                    }
                } else {
                    this.countries.push(location);
                }
            }
        } else {
            this.countries = window.Propuno.countries;
            this.cities = window.Propuno.cities;
            this.towns = window.Propuno.towns;
        }
    },
    methods: {
        selectedLocation(e) {
            this.location = e;
        },
        redirectToSearch(e) {
            e.preventDefault();
            window.location.href = window.Propuno.search_url + (this.location ? "in/" + formatLocationForURL(this.location) : "");
        }
    }
}
</script>
